.App {
  margin: 3rem;
}

.App-marka {
  font-size: 2rem;
}

footer {
  margin-top: 2rem;
  text-align: center;
}
